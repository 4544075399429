import { useEffect, useState, useRef } from 'react'
import styled, { keyframes } from 'styled-components'

const Bubble = styled.div`
  background-color: #DDD2FA;
  align-self: flex-start;
  padding:16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: ${props => (props.$loading ? 'inline-block' : 'none')};
`
const TypingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 17px;
`

const TypingAnimation = keyframes`
  0% {
    transform: translateY(0px);
    background-color:#857e96;
  }
  28% {
    transform: translateY(-7px);
    background-color:#9b93af; 
  }
  44% {
    transform: translateY(0px);
    background-color: #b1a8c8; 
  }
`
const Dot = styled.div`
  animation: ${TypingAnimation} 1.8s infinite ease-in-out;
  background-color: #857e96;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;

  &:nth-child(1) {
    animation-delay: 200ms;
  }

  &:nth-child(2) {
    animation-delay: 300ms;
  }

  &:nth-child(3) {
    animation-delay: 400ms;
  }
  &:last-child {
    margin-right: 0;
  }
`

const chatterBotSalute = { 
  id: Date.now(), 
  author: 'Chatterbot', 
  content: 'Hello human! My name is Chatterbot, and I\'m... a bot, obviously 🤖. Ask me whatever you wish and I... might (or might not) respond!👻', 
  sent: true 
}

const Typing = ({ secs, send }) => {
  const [loading, setLoading] = useState(true)
  
  const useTimeout = (callback, timer) => {
    const savedCallback = useRef()
  
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])
  
    useEffect(() => {
      const tick = () => savedCallback.current()
      
      if (timer !== '') {
        let id = setTimeout(tick, timer)
        return () => clearTimeout(id)
      }
    }, [timer])
  }

  useTimeout(() => { setLoading(false); greet() }, secs * 1000)

  const greet = () => { send(chatterBotSalute) }
  
  return (
    <Bubble $loading={loading}>
      <TypingWrapper>
        <Dot />
        <Dot />
        <Dot />
      </TypingWrapper>
    </Bubble>
  )
}

export default Typing
