import styled from 'styled-components'
import { Send } from  '@styled-icons/material/Send'
import { Input, Textbox, Button } from '../../layout'


const SendIcon = styled(Send)`
  &:hover {
    color: hsl(265, 100%, 75%);
  }
`

const Chatbox = ({ send, message, setMessage, loading }) => {

  const handleChange = event => setMessage({ id: Date.now(), author: 'John Doe', content: event.target.value, sent: true })
  const handleKeyPress = event => event.key === 'Enter' && sendMessage()
  const sendMessage = () => message.content && send(message)

  return (
      <Textbox>
        {loading ||
          <Input 
            aria-label="Type a message"
            type="text" 
            value={message.content} 
            placeholder="Type to start chatting" 
            onKeyPress={handleKeyPress} 
            onChange={handleChange} 
          />
        } 
          {message.content && 
            <Button aria-label="Send a message" onClick={() => sendMessage()}>
              <SendIcon 
                title="Send message" 
                size="36"
              />
            </Button>}  
        </Textbox>
  )
}

export default Chatbox
