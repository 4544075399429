import { Container } from '../../layout'
import Navbar from '../Navbar'
import ChatTray from '../ChatTray'
import Sidebar from '../Sidebar'
import { useState } from 'react'


const Chatterbox = () => {
  const [message, setMessage] = useState({ id: '', author: '', content: '', sent: false })
  const [messages, setMessages] = useState([])

  const send = message => {
    setMessages([...messages, message])
    setMessage({id: '', author: '', content: '', sent: false })
}

  const deleteMessage = id => setMessages(messages.filter(message => message.id !== id))

  const toggleMessageStatus = id => setMessages(messages.map(message => message.id === id ? { ...message, sent: !message.sent } : {...message}))

  const messageIsSelected = messages.slice().filter(message => !message.sent).length > 1 && true 
  
  const deleteAllSelected = () => setMessages(messages.filter(message => message.sent))

  return (
    <>
      <Navbar title="Chatterbox!" bgColor={'rgba(255, 255, 255, 0.95)'} />
      <Container>
        <Sidebar title="Conversations" />
        <ChatTray 
          send={send} 
          message={message} 
          setMessage={setMessage} 
          messages={messages} 
          setMessages={setMessages}
          deleteMessage={deleteMessage}
          toggleMessageStatus={toggleMessageStatus}
          messageIsSelected={messageIsSelected}
          deleteAllSelected={deleteAllSelected}  
        />
        <Sidebar title="Info Box" />
      </Container>
    </>
  )
}

export default Chatterbox
