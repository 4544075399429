import moment from 'moment';
import { Tray, Bar, Wrapper, BoxTitle } from '../../layout'
import Chatbox from '../Chatbox'
import ChatBubble from '../ChatBubbles'
import Typing from '../Typing'
import { Clear } from  '@styled-icons/material'
import styled from 'styled-components'
import { Button } from '../../layout'


const ClearIcon = styled(Clear)`
  &:hover {
    color: white;
    background-color: #D1436D; 
    border-radius: 100%;
  }
`

const ChatTray = ({ 
  send, 
  message, 
  setMessage, 
  messages, 
  deleteMessage, 
  toggleMessageStatus, 
  messageIsSelected, 
  deleteAllSelected 
}) => { 
  
  const dateParser = timestamp => moment(new Date(timestamp)).format('H:mm')

  return (  
    <Tray 
      bgColor={'rgba(255, 255, 255, 0.8)'} 
      send={send} 
      message={message} 
      messages={messages}
    >
      <Bar bgColor={'rgba(255, 255, 255, 0.5)'}>
        <BoxTitle>Chatterbot</BoxTitle>
      </Bar>
      <Wrapper bgColor="transparent">
        <Typing send={send} secs={3} />
          {messages.slice().reverse().map(message => 
            <ChatBubble 
              left={message.author === 'Chatterbot' && true} 
              key={message.id} 
              message={message} 
              messages={messages} 
              deleteMessage={deleteMessage}
              toggleMessageStatus={toggleMessageStatus}
              time={dateParser(message.id)}
            />)}
      </Wrapper>
      <Button aria-label="Delete all selected" onClick={() => deleteAllSelected()}>
        {messageIsSelected && <ClearIcon title="Delete selected messages" size="36"/>}
      </Button>
      <Chatbox 
        message={message} 
        send={send} 
        setMessage={setMessage} 
      />
    </Tray>
  )
}

export default ChatTray
