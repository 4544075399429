import styled, { createGlobalStyle } from 'styled-components'
import { px2vw, px2vh } from '../utils/px2vw'


export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
      font-size: ${px2vw(24)};
      @media (min-width: 768px) {
        font-size: ${px2vw(18)};
      }

      @media (min-width: 1024px) {
        font-size: ${px2vw(16)};
      }
    }
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  background:
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1123 258'><path d='M1124,2c0,0 0,256 0,256l-1125,0l0,-48c0,0 16,5 55,5c116,0 197,-92 325,-92c121,0 114,46 254,46c140,0 214,-167 572,-166Z' style='fill: hsla(0, 0%, 100%, 0.6)' /></svg>") no-repeat bottom,
    linear-gradient(
      to bottom,
      #6200EE,
      #03DAC5 85%,
      #03DAC5 99%
    );

  @media (min-width: 1024px) {
      flex-wrap: nowrap;
  }
`
export const Box = styled.div`
  margin-top: 5vh;
  display: flex;
  width: 18.66vw;
  flex-direction: column;
  padding: ${px2vw(11)} ${px2vw(20)};
  background-color: ${props => props.bgColor};

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 768px) {
    width: 18.66vw;
  }

  @media (min-width: 1024px) {
    width: 18.66vw;
  }
`
export const Wrapper = styled.p`
  flex-direction: column-reverse;
  display: flex;
  align-items: flex-end;
  padding: ${px2vw(11)} ${px2vw(20)};
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  background-color: transparent;
  overflow-y: auto;
  overflow-x: hidden;
  flex-basis: auto;
`

export const Tray = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  width: 62.66vw;
  background-color: ${props => props.bgColor};
  justify-content: space-between;
  height: 95vh;
  min-height: ${px2vw(200, 320)};
  padding: 0;

  @media (max-width: 768px) {
    width: 100vw;
  }

  @media (min-width: 768px) {
    width: 62.66vw;
    height: 95vh;
    min-height: ${px2vw(200, 768)};
  }

  @media (min-width: 1024px) {
    width: 62.66vw;
    height: 95vh;
    min-height: ${px2vw(300)};
  }
`
export const Bar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${px2vw(20)};
  width: 100%;
  height: 5vh;
  flex-direction: row;
  background-color: ${props => props.bgColor};
`

export const Navigation = styled(Bar)`
  position: fixed;
  justify-content: center;
  align-items: center;
  padding: ${px2vw(5)} ${px2vw(20)};

  & > img {
    margin-right: 10px;
    align-self: center;
  }

  & > h1 {
    margin-bottom: 2px;
  }

  @media (min-width: 768px) {
    width: 100vw;
  }

  @media (min-width: 1024px) {
    width: 100vw;
  }
`

export const BoxTitle = styled.h3`
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  color:  #003049;
  font-size: 1rem;
  
  @media (min-width: 1024px) {
    font-size: 1rem;
  }

  @media (min-width: 375px) {
    font-size: 2rem;
  }

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
  }

  @media (min-width: 1366px) {
    font-size: 1rem;
  }

  @media (min-width: 1920px) {
    font-size: 1rem;
  }
`

export const AppTitle = styled.h1`
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  display: inline;
  color: #003049;
  font-size: 4rem;

  @media (min-width: 375px) {
    font-size: 4rem;
  }

  @media (min-width: 768px) {
    font-size: 3.5rem;
  }

  @media (min-width: 1024px) {
    font-size: 3rem;
  }

  @media (min-width: 1366px) {
    font-size: 2rem;
  }

  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
`

export const Input = styled.input`
  font-size: 18px;
  background: transparent;
  border: none;
  outline: none;
  width: 100vw;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
`
export const Textbox = styled.div`
  min-height: ${px2vh(47)};
  display: flex;
  border-radius: 100px;
  padding: ${px2vw(4)} ${px2vw(15)};
  margin: ${px2vw(15)} ${px2vw(26)} ${px2vw(20)} ${px2vw(26)};
  background-color: #F0F2F5;
`

export const Button = styled.button`
  border: none;
  color: #757575;
  background-color: transparent;
  padding: 0;
`
