import { Box, BoxTitle } from '../../layout'

const translucidWhite = 'rgba(255, 255, 255, 0.9)'

const Sidebar = ({ title }) => 
  <Box bgColor={translucidWhite}>
    <BoxTitle>{title}</BoxTitle>
  </Box>

export default Sidebar
