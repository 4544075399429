import styled from 'styled-components'
import { Delete } from  '@styled-icons/material'

const TalkBubble = styled.div`
  margin: 0.7rem;
  position: relative;
  width: fit-content;
  background-color: ${props => props.left ? props.$colors.purple.background : props.$colors.orange.background };
  padding: 0.2rem 0.5rem;
  border-radius: 20px;
  border-bottom-${ props => props.left ? direction.left : direction.right }-radius: 2px;
  border-bottom-${props => props.left ? direction.right : direction.left}-radius: 20px;
  align-self: ${props => props.left ? 'flex-start' : 'flex-end'};
`

const TalkBubbleWrapper = styled.div`
  padding: 0.5em 1em;
  text-align: left;
`

const TalkBubbleText = styled.p`
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  
  @media (min-width: 375px) {
    font-size: 2rem;
  }

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
  }

  @media (min-width: 1366px) {
    font-size: 1rem;
  }

  @media (min-width: 1920px) {
    font-size: 1rem;
  }
`

const FooterWrapper = styled(TalkBubbleWrapper)`
  display: flex;
  justify-content: flex-end;
  padding: 0;
  align-items: center;
`

const UserName = styled(TalkBubbleText)`
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  color: ${props => props.left ? props.$colors.purple.username : props.$colors.orange.username };
`
const Time = styled(UserName)`
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  color:  ${props => props.left ? props.$colors.purple.active : props.$colors.orange.active };
  margin-right: 0.3em;
`

const DeleteIcon = styled(Delete)`
  color: ${props => props.left ? props.$colors.purple.active : props.$colors.orange.active };
  &:hover {
    color: ${props => props.left ? props.$colors.purple.hover : props.$colors.orange.hover };
  }
`

const Button = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
`

const Selectedwrapper = styled.div`
  display: flex;
  margin-left: .3rem;
`

const Checkbox = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  
  &:checked ~ span {
    border-color: #00EA90;
    box-shadow: 0px 0px 0px 15px #D1436D inset;
  }

  &:checked ~ span:after {
    opacity: 1;
    transform: scale(1);
  }

  & span:after {
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 4;
    position: absolute;
  }
`

const SelectedIcon = styled.span`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100px;
  background-color: ${props => props.left ? props.$colors.purple.active : props.$colors.orange.active };
  box-shadow: 0px 0px 0px 0px ${props => props.left ? props.$colors.purple.hover : props.$colors.orange.hover} inset;
  transition: all 0.15s cubic-bezier(0, 1.05, 0.72, 1.07);

` 

const Label = styled.label`
  color: transparent;
  position: absolute;
  z-index: 10;
  padding-left: 50px;
`

const direction = { left: 'left', right: 'right' }

const colors = { 
  orange: { background: '#f6e4cd', username: '#f77f00', active: '#aca090', hover: '#d8a05c' }, 
  purple: { background: '#DDD2FA', username: '#410AC6', active: '#aca090', hover: '#9b93af' }
}

const ChatBubble = ({ left, message, deleteMessage, toggleMessageStatus, time }) => 
    <TalkBubble 
      left={left} 
      $colors={colors} 
      toggleMessageStatus={toggleMessageStatus}
    >
      <TalkBubbleWrapper >
        <UserName left={left} $colors={colors}>
          {message.author} 
        </UserName>
        <TalkBubbleText aria-label="This is a message">
          {message.content}
        </TalkBubbleText>
      </TalkBubbleWrapper>
      <FooterWrapper>
        <Time $colors={colors}>
          {time}
        </Time>
        <Button 
          onClick={() => deleteMessage(message.id)} 
          aria-label={`Delete ${message.content}`}
        >
          <DeleteIcon 
            title="Delete this message" 
            left={left} 
            $colors={colors} 
            size="20"
          />
        </Button>
        <Selectedwrapper $colors={colors}>
          <Checkbox 
            id={message.id}
            aria-label={message.content}
            value={message.content} 
            type="checkbox" 
            $colors={colors}
            onClick={() => toggleMessageStatus(message.id)}
          />
          <SelectedIcon $colors={colors}/>
          <Label htmlFor={message.id}>.</Label>
        </Selectedwrapper>
      </FooterWrapper>
    </TalkBubble> 

export default ChatBubble
